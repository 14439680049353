<template>
  <div
    v-if="data"
    class="h-[calc(100vh_-_366px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line"
  >
    <pre>{{ data }}</pre>
  </div>
  <EmptyContentComponent
    v-else
    type="Json"
  />
</template>
<script>
import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'

export default {
  name: 'JsonTab',
  components: { EmptyContentComponent },
  props: {
    data: {
      type: String,
      default: ''
    }
  }
}
</script>
