<template>
  <div
    v-if="isLoading"
    class="h-[calc(100vh_-_365px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line flex justify-center items-center"
  >
    <half-circle-spinner :animation-duration="1000" :size="60" color="#262A41" />
  </div>
  <div
    v-if="rawData && !isLoading"
    class="h-[calc(100vh_-_366px)] p-8 mx-2 mt-2 overflow-auto whitespace-pre-line"
  >
    <pre class="font-courier">{{ rawData }}</pre>
  </div>
  <EmptyContentComponent
    v-else-if="!rawData && !isLoading"
    type="Raw"
  />
</template>
<script>
import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'
import { HalfCircleSpinner } from 'epic-spinners'
import publicMessagesService, { publicRaw } from '@/http/services/public/publicMessages.service'
import privateMessagesService, { privateRaw } from '@/http/services/private/privateMessages.service'

export default {
  name: 'RawTab',
  components: {
    EmptyContentComponent,
    HalfCircleSpinner
  },
  data () {
    return {
      isLoading: false,
      rawData: ''
    }
  },
  mounted () {
    this.getRawData()
  },
  methods: {
    async getRawData () {
      const id = this.$route.query.msgid
      if (!id) this.$router.push({ name: 'PrivateMailInbox' })
      this.isLoading = true

      if (this.$route.name === 'PublicMail') {
        if (!publicRaw) {
          await publicMessagesService.fetchRawData(id)
        }
        this.rawData = publicRaw
      } else {
        if (!privateRaw) {
          await privateMessagesService.fetchRawData(id)
        }
        this.rawData = privateRaw
      }

      this.isLoading = false
    }
  }
}
</script>
