import BaseAxiosInstance from '@/axiosConfig'
import { useToast } from 'vue-toastification'

const toast = useToast()
const domain = process.env.VUE_APP_DOMAIN
const prefix = '/devel'
// const token = process.env.VUE_APP_API_TOKEN

const generateUrl = (url) => {
  return domain + prefix + url
}

export let messagesDomain = 'testinator.com'
export let inbox = '*'
export let privateRaw = null

export function clearRawData () {
  privateRaw = null
}

const API = {
  DOMAINS: {
    GET_DOMAINS: () => generateUrl('/domains/')
  },
  MESSAGE: {
    FETCH_MESSAGE: (id) => generateUrl(`/fetch_private?msgid=${id}`),
    FETCH_MESSAGE_DETAIL: (domain, id, detailType) => generateUrl(`domains/${domain}/messages/${id}/attachments/${detailType}`),
    DELETE_MESSAGE: (id) => generateUrl(`/delete_private?msgid=${id}`),
    FETCH_RAW_DATA: (id) => generateUrl(`/fetch_private?format=raw&msgid=${id}`),
    FORWARD_MESSAGE: (id) => generateUrl(`/forward_private?msgid=${id}`),
    SAVE_ATTACH: (inbox, msgId, attachIndex) => generateUrl(`${domain}/api/v2/domains/private/inboxes/${inbox}/messages/${msgId}/attachments/${attachIndex}`)
  }
}

const PrivateMessagesService = {
  async fetchDomains () {
    return BaseAxiosInstance.get(API.DOMAINS.GET_DOMAINS())
  },

  async fetchPrivateMessage (id) {
    return BaseAxiosInstance.get(API.MESSAGE.FETCH_MESSAGE(id))
  },

  async fetchRawData (id) {
    privateRaw = await BaseAxiosInstance.get(API.MESSAGE.FETCH_RAW_DATA(id))
  },

  async forwardPrivateEmails (MSGs) {
    const requests = MSGs.map(msg => {
      return BaseAxiosInstance.get(API.MESSAGE.FORWARD_MESSAGE(msg))
    })

    Promise.all(requests)
      .then(responses => {
        toast.success(`${responses.length === 1 ? 'Email' : 'Emails'} was sent in your private mail`)
      })
  },

  async deletePrivateMessages (IDs) {
    const requests = IDs.map(id => {
      return BaseAxiosInstance.delete(API.MESSAGE.DELETE_MESSAGE(id))
    })

    Promise.all(requests)
      .then(responses => {
        if (responses[0]?.status === 'ok') toast.success(`${responses.length === 1 ? 'Email' : 'Emails'} was deleted`)
      })
  },

  saveAttach (inbox, msgId, attachIndex) {
    window.location = API.SAVE_ATTACH(inbox, msgId, attachIndex)
  },

  setDomain (newDomain) {
    messagesDomain = newDomain
  },

  setInbox (newInbox) {
    inbox = newInbox
  }
}

export default PrivateMessagesService
