<template>
  <div
    v-if="data.length"
    class="h-[calc(100vh_-_366px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line"
  >
      <div
        class="mx-4 my-2 flex justify-start items-center text-[#2979FF]"
        v-for="i of data" :key="i"
      >
        <svg
          class="mr-2 w-[8px] h-[8px]"
          viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" fill="#9294A0"/>
        </svg>
        <a
          :href="i.link + i.redirectUrl"
          target="_blank"
          class="max-w-[700px] whitespace-nowrap truncate"
        >
          {{ i.text ? i.text : i.link }}
        </a>
      </div>
  </div>
  <EmptyContentComponent
    v-else
    type="Links"
  />
</template>
<script>

import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'

export default {
  name: 'LinksTab',
  components: { EmptyContentComponent },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>
