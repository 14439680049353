<template>
  <div class="mt-4 flex flex-col justify-start items-center">
    <form @submit.prevent="submitForm">
      <div class="w-full h-[20px] mb-1 text-sm text-red-500 text-center">
        {{error ? error : ''}}
      </div>
      <input
        type="email"
        name="email"
        placeholder="Email"
        required
        class="w-full mb-6 py-[6px] px-3 rounded text-[14px] border border-[#c8c8c8] placeholder-color-[#b7bbc0] bg-white outline-none"
      >
      <input
        type="password"
        name="password"
        placeholder="Password"
        required
        class="w-full mb-6 py-[6px] px-3 text-[14px] rounded border border-[#c8c8c8] placeholder-color-[#b7bbc0] bg-white outline-none"
      >
      <div class="flex flex-wrap justify-around items-center">
        <button
          type="submit"
          class="mx-1 px-4 py-2 rounded border border-[#c8c8c8] bg-white hover:bg-[#e6e6e6] text-[12px] text-[#333]"
        >
          Log in
        </button>
        <router-link
          class="mx-1 text-[#5a738e] text-sm"
          :to="{ name: 'ResetPassword' }"
        >
          Lost your password?
        </router-link>
      </div>
    </form>
    <div class="my-4 w-full h-[1px] bg-[#D8D8D8]"></div>
    <router-link
      class="text-[#5a738e] text-sm"
      :to="{ name: 'SsoLogin' }"
    >
      Login with SSO
    </router-link>
    <div class="my-4 w-full h-[1px] bg-[#D8D8D8]"></div>
  </div>
</template>

<script>

import AuthService from '@/http/services/auth.service'

export default {
  name: 'EmailLoginComponent',
  data () {
    return {
      error: ''
    }
  },
  methods: {
    async submitForm (e) {
      const form = e.target
      const email = form.elements.email.value
      const password = form.elements.password.value

      const queryData = {
        email, password
      }
      const formData = new FormData(e.target)

      await AuthService.loginWithEmailAndPass(formData, queryData)
    }
  }
}
</script>
<style scoped>
  input {
    box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, .08) inset;
  }
</style>
