<template>
  <tr class="text-[#1A1A2E] text-[18px] even:bg-[#E5E5E9] border-b border-[#A0A0A6] tracking-wide cursor-pointer">
    <td class="p-4 bg-transparent whitespace-nowrap">
      <input
        type="checkbox"
        class="hidden"
        :id="rowData.id"
      />
      <label
        v-if="rowData.checked"
        :for="rowData.id"
        @click.stop="handleCheckboxClick"
        class="w-[20px] h-[20px] inline-block align-middle box-border rounded-md border-2 border-[#0056CC] bg-[#0056CC] cursor-pointer"
      >
        <svg
          class="w-[12px] h-[8px] mx-auto mt-1"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke-width="8" d="M10.0652 0L11.5 1.41176L4.81391 8L0.5 3.76471L1.93478 2.35294L4.81391 5.17647L10.0652 0Z" fill="white"/>
        </svg>
      </label>
      <label
        v-else
        :for="rowData.id"
        @click.stop="handleCheckboxClick"
        class="w-[20px] h-[20px] inline-block align-middle rounded-md border-2 border-[#A0A0A6] bg-[#FFFFFF] cursor-pointer"
      ></label>
      <p class="ml-3 inline-block align-middle whitespace-nowrap text-[#1A1A2E]">{{ rowData.to }}</p>
    </td>
    <td class="p-4 text-start whitespace-nowrap">
      <p class="truncate text-[#1A1A2E]">{{ rowData.from }}</p>
    </td>
    <td class="max-w-[40vw] 2xl:max-w-[55vw] p-4 text-start whitespace-nowrap">
      <p class="truncate text-[#1A1A2E]">{{ rowData.subject }}</p>
    </td>
    <td class="p-4 text-end whitespace-nowrap">
      <p class="text-[#1A1A2E]">{{ getTimeFormat(rowData.seconds_ago) }}</p>
    </td>
  </tr>
</template>

<script>
import { convertSecondsToTime } from '@/utils/time.utils'

export default {
  name: 'tableRow',
  props: {
    rowData: Object
  },
  data () {
    return {
      isChecked: false
    }
  },
  methods: {
    getTimeFormat (time) {
      return convertSecondsToTime(time)
    },
    handleCheckboxClick (event) {
      event.stopPropagation()
      this.$emit('row-check', this.rowData.id)
    }
  }
}
</script>
