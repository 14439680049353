<template>
  <thead class="text-[#9294A0] text-[14px] uppercase tracking-wide border-b  border-[#A0A0A6]">
  <tr>
    <th class="flex px-4 py-2 text-start">
      <input
        type="checkbox"
        id="check-all"
        class="hidden"
        @change="$emit('set-is-checked-all')"
      />
      <label
        v-if="isCheckedAll"
        for="check-all"
        class="w-[20px] h-[20px] flex justify-center items-center box-border rounded-md border-2 border-[#2979FF] bg-[#2979FF] cursor-pointer"
      >
        <svg
          class="w-[12px] h-[8px]"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke-width="8" d="M10.0652 0L11.5 1.41176L4.81391 8L0.5 3.76471L1.93478 2.35294L4.81391 5.17647L10.0652 0Z" fill="white"/>
        </svg>
      </label>
      <label
        v-else
        for="check-all"
        class="w-[20px] h-[20px] rounded-md border-2 border-[#D1D1D6] bg-[fff] cursor-pointer"
      ></label>
      <span class="ml-3">to</span>
    </th>
    <th scope="col" class="px-4 py-2 text-start whitespace-nowrap">from</th>
    <th scope="col" class="px-4 py-2 text-start whitespace-nowrap">subject</th>
    <th class="px-4 py-2 text-end whitespace-nowrap">
      time received
    </th>
  </tr>
  </thead>
</template>

<script>

export default {
  name: 'tableHead',
  props: {
    isCheckedAll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async changeSortType () {
      this.$emit('update-messages-list')
    }
  }
}
</script>
