<template>
  <div
    v-if="data"
    class="h-[calc(100vh_-_365px)] p-8 xl:mx-2 mt-2 overflow-auto whitespace-pre-line"
  >
    <div
      v-for="(i, index) of mapSmptLogsData" :key="index"
      class="w-full flex justify-start items-start"
    >
      <div class="w-[80px] ml-2 font-courier">
        {{ i.time ? `${i.time} ms` : ' '}}
      </div>
      <div class="w-[150px] ml-2 font-courier">
        {{ i.event }}
      </div>
      <div class="w-[80px] ml-2 font-courier">
        {{ setStatusArrow(i.event) }}
      </div>
      <div class="ml-2 w-[500px] font-courier">
        {{ i.log }}
      </div>
    </div>
  </div>
  <EmptyContentComponent
    v-else
    type="SMTP Logs"
  />
</template>
<script>
import { createJsonVersion } from '@/helpers/tabsFormathelpers'
import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'

export default {
  name: 'SmtpLogsTab',
  components: { EmptyContentComponent },
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  computed: {
    mapSmptLogsData () {
      const mappedData = createJsonVersion(this.data)
      return mappedData ? mappedData.filter(e => e.event) : []
    }
  },
  methods: {
    setStatusArrow (status) {
      switch (status) {
        case 'OUTGOING':
          return '==>'
        case 'INCOMING':
          return '<=='
        default:
          return ''
      }
    }
  }
}
</script>
