import BaseAxiosInstance from '@/axiosConfig'
import router from '@/router'

const API = {
  LOGIN_WITH_EMAIL_AND_PASS: (email, password) => `/devel/manyauth/many_login?email=${email}&password=${password}`,
  LOGIN_WITH_SSO: (email) => `/devel/manyauth/many_loginsso?email=${email}`,
  FORGOT_PASSWORD: (email) => `/devel/manyauth/many_forgotpw?email=${email}`
}

const AuthService = {
  async loginWithEmailAndPass (formData, queryData) {
    try {
      const response = await BaseAxiosInstance.post(
        API.LOGIN_WITH_EMAIL_AND_PASS(queryData.email, queryData.password),
        formData
      )

      if (response.status === 200) {
        await router.push({ name: 'PrivateMailInbox' })
      }

      return response
    } catch (error) {
      console.error('Error during loginWithEmailAndPass:', error)
      throw error
    }
  },
  async loginWithSSO (formData, queryData) {
    try {
      const response = await BaseAxiosInstance.post(API.LOGIN_WITH_SSO(queryData.email), formData)

      if (response.status === 200) {
        await router.push({ name: 'PrivateMailInbox' })
      }

      return response
    } catch (error) {
      console.error('Error during loginWithSSO:', error)
      throw error
    }
  },
  async resetPassword (formData, queryData) {
    try {
      const response = await BaseAxiosInstance.post(API.FORGOT_PASSWORD(queryData.email), formData)

      if (response.status === 200) {
        await router.push({ name: 'PrivateMailInbox' })
      }

      return response
    } catch (error) {
      console.error('Error during resetPassword:', error)
      throw error
    }
  }
}

export default AuthService
