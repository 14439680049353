<template>
  <div class="w-full h-full p-2 md:pt-4 md:pb-2 md:px-4 bg-white rounded-xl">
    <div
      class="w-full flex flex-wrap flex-col lg:flex-row justify-center md:justify-between items-center">
      <h2 class="h-[40px] mt-2 mx-2 text-[#262A41] text-2xl font-bold">Private Messages</h2>
      <PrivateControllersComponent :mailDomains="mailDomains"/>
    </div>
    <div
      v-if="mailsList.length"
      class="flex flex-col md:flex-row flex-wrap items-center"
      :class="itemsPerPage < total ? 'justify-between' : 'justify-center lg:justify-end'"
    >
      <div
        v-if="itemsPerPage < total"
        class="mx-auto md:mx-4 my-4 flex text-[#262A41]"
      >
        <button
          :disabled="currentPage === 1"
          @click="prevPage"
        >
          <svg
            width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.6595 6.17051H3.3512L7.41786 2.10384C7.74286 1.77884 7.74286 1.24551 7.41786 0.920508C7.09286 0.595508 6.56786 0.595508 6.24286 0.920508L0.751196 6.41217C0.426196 6.73717 0.426196 7.26217 0.751196 7.58717L6.24286 13.0788C6.56786 13.4038 7.09286 13.4038 7.41786 13.0788C7.74286 12.7538 7.74286 12.2288 7.41786 11.9038L3.3512 7.83717H12.6595C13.1179 7.83717 13.4929 7.46217 13.4929 7.00384C13.4929 6.54551 13.1179 6.17051 12.6595 6.17051Z"
              :fill="currentPage <= 1 ? '#9294A0' : '#2979FF'"
            />
          </svg>
        </button>
        <div class="min-w-[90px] flex justify-center items-center text-[18px] font-futura">
          <p v-if="itemsPerPage < total">{{ from + 1 }}</p>
          <span v-if="itemsPerPage < total" class="mx-1">-</span>
          <p>{{ currentPage === totalPages ? total : to }}</p>
        </div>
        <button
          :disabled="currentPage === totalPages"
          @click="nextPage"
        >
          <svg
            width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.34078 7.83312H10.6491L6.58245 11.8998C6.25745 12.2248 6.25745 12.7581 6.58245 13.0831C6.90745 13.4081 7.43245 13.4081 7.75745 13.0831L13.2491 7.59145C13.5741 7.26645 13.5741 6.74145 13.2491 6.41645L7.76578 0.916449C7.44078 0.591449 6.91578 0.591449 6.59078 0.916449C6.26578 1.24145 6.26578 1.76645 6.59078 2.09145L10.6491 6.16645H1.34078C0.882446 6.16645 0.507446 6.54145 0.507446 6.99978C0.507446 7.45812 0.882446 7.83312 1.34078 7.83312Z"
              :fill="currentPage === totalPages ? '#9294A0' : '#2979FF'"
            />
          </svg>
        </button>
      </div>
      <div
        v-if="isCheckedMessagesExist"
        class="flex justify-center items-center"
      >
        <ForwardEmailComponent
          isOnceMessage
          @forward-emails="forwardEmail"
        />
        <div
          class="h-[22px] mx-4 border-x border-[#D1D1D6]"></div>
        <DeleteEmailComponent
          isOnceMessage
          @delete-checked-email="deleteCheckedEmails"
        />
      </div>
    </div>
    <PrivateMailsTableComponent
      v-if="mailsList.length"
      :tableData="filteredList"
      @check-all="checkAll"
      @check-item="checkItem"
      @update-messages-list="$emit('update-messages-list')"
    />
    <div
      v-else
      class="w-full h-[calc(100vh_-_270px)] flex justify-center items-center"
    >
      <EmptyContentComponent type="Messages"/>
    </div>
  </div>
</template>

<script>
import DeleteEmailComponent from '@/components/include/DeleteEmailComponent.vue'
import EmptyContentComponent from '@/components/include/EmptyContentComponent.vue'
import PrivateControllersComponent from '@/components/private/mailsList/PrivateControllersComponent.vue'
import PrivateMailsTableComponent from '@/components/private/mailsList/table/PrivateMailsTableComponent.vue'
import ForwardEmailComponent from '@/components/include/ForwardEmailComponent.vue'
import PrivateMessagesService from '@/http/services/private/privateMessages.service'

export default {
  name: 'PrivateMailsListComponent',
  components: {
    ForwardEmailComponent,
    PrivateMailsTableComponent,
    PrivateControllersComponent,
    EmptyContentComponent,
    DeleteEmailComponent
  },
  props: {
    mailsList: {
      type: Array,
      default: () => []
    },
    mailDomains: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentPage: 1,
      itemsPerPage: 50,
      pages: {
        from: 0,
        to: 50
      }
    }
  },
  watch: {
    async $route () {
      this.setPagesState()
    }
  },
  computed: {
    total () {
      return this.mailsList.length
    },
    totalPages () {
      return Math.ceil(this.total / this.itemsPerPage)
    },
    from () {
      if (this.total === 0) return 0
      return (this.currentPage - 1) * this.itemsPerPage
    },
    to () {
      if (this.total < this.itemsPerPage) return this.total
      else if (this.currentPage === this.totalPages) return this.total

      return this.from + this.itemsPerPage
    },
    filteredList () {
      return this.mailsList.length < this.itemsPerPage ? this.mailsList : this.mailsList.slice(this.pages.from, this.pages.to)
    },
    isCheckedMessagesExist () {
      return this.mailsList.some(i => i.checked)
    }
  },
  methods: {
    setPagesState () {
      this.currentPage = 1
      this.pages = {
        from: 0,
        to: 50
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
      this.filter()
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
      this.filter()
    },
    filter () {
      this.pages = { from: this.from, to: this.to }
    },
    checkAll (isChecked) {
      this.mailsList.forEach(i => {
        i.checked = isChecked
      })
    },
    checkItem (id) {
      const index = this.mailsList.findIndex(i => i.id === id)
      const data = this.mailsList

      data[index].checked = !data[index].checked
    },
    async forwardEmail () {
      const ids = this.mailsList.filter(i => i.checked).map(mail => {
        return mail.id
      })

      await PrivateMessagesService.forwardPrivateEmails(ids)
    },
    async deleteCheckedEmails () {
      const filtered = this.mailsList.filter(i => !i.checked)
      const ids = this.mailsList.filter(i => i.checked).map(mail => {
        return mail.id
      })

      await PrivateMessagesService.deletePrivateMessages(ids)
      setTimeout(() => {
        this.$router.push({ name: 'PrivateMailInbox' })
      }, 1000)

      this.$emit('update-data', filtered)
    }
  }
}
</script>
